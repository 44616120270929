<template>
    <div id="activity">
        <component-education-navbar logoColor="white"></component-education-navbar>
        <img src="@/assets/images/education/education_online_bg.png" alt="" id="bg">
        <div class="content">
            <div class="activities">
                <div class="cnTitle">
                    <img :src="require('@/assets/images/education/topic_activity.png')" alt="">
                </div>
                <div class="list">
                    <activities-component :list="activities"></activities-component>
                    <div class="pagination" v-if="total > 0">
                        <el-pagination
                            background
                            layout="prev, pager, next"
                            @current-change="getList"
                            :current-page.sync="pageCurrent"
                            :page-size="pageSize"
                            :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
            <component-education-bottom :showBgColor="false"></component-education-bottom>
        </div>
    </div>
</template>
<script>
    import {urls} from '@/lib/common'
    import ComponentEducationNavbar from '@/components/ComponentEducationNavbar'
    import ComponentEducationBottom from '@/components/ComponentEducationBottom'
    import ActivitiesComponent from './component/activitiesComponent.vue'

    export default {
        name: "Education",
        components: {
            ComponentEducationNavbar,
            ComponentEducationBottom,
            ActivitiesComponent
        },
        data() {
            return {
                activities: [
                    {
                        id: 1,
                        cnLogo: require('@/assets/images/education/2b4dcce558c7653f4c9d020ceb031dc1.png'),
                        cnTitle: '2019年机器人技术培训师考证开始报名啦！',
                        price: '',
                        updateTime: '2020-12-01',
                        cnContentSummary: '邦宝教育邦宝教育7月11日关于专业人才库机器人技术考评管理中心机器人技术师资培训…'
                    }, {
                        id: 2,
                        cnLogo: require('@/assets/images/education/00a62086ea1f948f112480b7f09c9977.png'),
                        cnTitle: '汕头市科协领导调研邦宝益智科普教育基地',
                        price: '',
                        updateTime: '2020-12-01',
                        cnContentSummary: '邦宝教育邦宝教育 5月16日，汕头市科协党组书记、副主席洪祥武，市科协主席马清亮…'
                    }, {
                        id: 3,
                        cnLogo: require('@/assets/images/education/6b7163ee2b78604575143c58f3522418.png'),
                        cnTitle: '人工智能夏令营激发科学创新正能量！',
                        price: '',
                        updateTime: '2020-12-01',
                        cnContentSummary: '邦宝教育邦宝教育（剧透：文末有彩蛋哦！）夏天有数不尽的精彩，夏天有挡不住的疯狂'
                    }, {
                        id: 4,
                        cnLogo: require('@/assets/images/education/0739c5b088fa4be7d271e8278ced03c1.png'),
                        cnTitle: '【粤东站】全国青少年机器人技术等级考试成功举办啦！',
                        price: '',
                        updateTime: '2020-12-01',
                        cnContentSummary: '邦宝教育邦宝教育2019年7月20日，邦宝教育联合专业人才库—全国机器人技术考评…'
                    }, {
                        id: 5,
                        cnLogo: require('@/assets/images/education/2b4dcce558c7653f4c9d020ceb031dc1.png'),
                        cnTitle: '2019年机器人技术培训师考证开始报名啦！',
                        price: '',
                        updateTime: '2020-12-01',
                        cnContentSummary: '邦宝教育邦宝教育7月11日关于专业人才库机器人技术考评管理中心机器人技术师资培训…'
                    }, {
                        id: 6,
                        cnLogo: require('@/assets/images/education/00a62086ea1f948f112480b7f09c9977.png'),
                        cnTitle: '汕头市科协领导调研邦宝益智科普教育基地',
                        price: '',
                        updateTime: '2020-12-01',
                        cnContentSummary: '邦宝教育邦宝教育 5月16日，汕头市科协党组书记、副主席洪祥武，市科协主席马清亮…'
                    }, {
                        id: 7,
                        cnLogo: require('@/assets/images/education/6b7163ee2b78604575143c58f3522418.png'),
                        cnTitle: '人工智能夏令营激发科学创新正能量！',
                        price: '',
                        updateTime: '2020-12-01',
                        cnContentSummary: '邦宝教育邦宝教育（剧透：文末有彩蛋哦！）夏天有数不尽的精彩，夏天有挡不住的疯狂'
                    }, {
                        id: 8,
                        cnLogo: require('@/assets/images/education/0739c5b088fa4be7d271e8278ced03c1.png'),
                        cnTitle: '【粤东站】全国青少年机器人技术等级考试成功举办啦！',
                        price: '',
                        updateTime: '2020-12-01',
                        cnContentSummary: '邦宝教育邦宝教育2019年7月20日，邦宝教育联合专业人才库—全国机器人技术考评…'
                    }
                ],
                total: 0,
                pageCurrent: 1,
                pageSize: 8,
                navId: '',

            };
        },
        methods: {
            getList() {
                this.$http.defaults.headers.get['Web-Site'] = 2;
                const params = {
                    navigationId: this.navId,
                    pageSize: this.pageSize,
                    currentPage: this.currentPage
                }

                this.$http.get(urls.getSubpageList, {params}).then(res => {
                    console.log(res);
                    if (res.data.status === 200) {
                        this.activities = res.data.data.records;
                        this.total = res.data.total;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            }
        },
        mounted() {
            this.navId = atob(decodeURIComponent(this.$route.query.n));
            console.log(this.navId);
            this.getList();
        }
    };
</script>
<style lang="less" scoped>
    @deep: ~'>>>';
    #activity {
        min-width: 1380px;
        position: relative;
        #bg {
            position: absolute;
            left: 0;
            top: 0;
        }
        .content {
            width: 100%;
            padding-top: 80px;
            position: absolute;
            left: 0;
            top: 0;
            .activities {
                margin-top: 32vw;
                .cnTitle {
                    width: 10.5vw;
                    margin: 2vw auto 3vw;
                }
                .list {
                    width: 70vw;
                    margin: auto;
                    .pagination {
                        text-align: center;
                    }
                }
            }
        }
    }


    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        #activity{
            min-width: 320px;
            padding-top: 80px;
            background: linear-gradient(to right,#019EEC 0%,#019EEC 30%,#22CDFE 90% ,#23CEFF 100%);
            background: -moz-linear-gradient(to right,#019EEC 0%,#019EEC 30%,#22CDFE 90% ,#23CEFF 100%);
            background: -webkit-linear-gradient(to right,#019EEC 0%,#019EEC 30%,#22CDFE 90% ,#23CEFF 100%);
            background: -o-linear-gradient(to right,#019EEC 0%,#019EEC 30%,#22CDFE 90% ,#23CEFF 100%);
            background: -ms-linear-gradient(to right,#019EEC 0%,#019EEC 30%,#22CDFE 90% ,#23CEFF 100%);
            &>img{
                display: block;
                background-color: #fff;
            }

            #bg{
                position: relative;
            }

            .content{
                padding-top: 80px;
                .activities{
                    padding-top: 40%;
                    margin-top: 0;
                    .cnTitle{
                        width: 160px;
                    }
                    .list{
                        width: 90%;
                    }
                }
            }
        }
    }
</style>